import dayjs, { extend as dayjsExtend } from 'dayjs'
import 'dayjs/locale/ko'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjsExtend(customParseFormat)

const secToMinSec = (seconds: number) => {
  const locale = process.env.NEXT_PUBLIC_LANGUAGE || 'ko-KR'
  switch (locale) {
    case 'en': {
      const minutes = secToMin(seconds)
      const remainingSeconds = seconds % 60
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    }
    default:
      return secToMinSecKo(seconds)
  }
}

const secToMin = (seconds: number) => {
  return Math.floor(seconds / 60)
}

const secToMinSecKo = (seconds: number) => {
  const minutes = secToMin(seconds)
  const remainingSeconds = seconds % 60
  return `${minutes}분 ${remainingSeconds.toString().padStart(2, '0')}초`
}

const removeSeconds = (timeString?: string | null) => {
  if (!timeString) {
    return ''
  }
  return timeString?.substring(0, 5)
}

const getBusinessHours = (
  openedAt: string | undefined | null,
  closedAt: string | undefined | null
): string | undefined => {
  if (!openedAt || !closedAt) {
    return undefined
  }
  const openedAtDayjs = dayjs(openedAt, 'HH:mm:ss')
  const closedAtDayjs = dayjs(closedAt, 'HH:mm:ss')

  if (!openedAtDayjs.isValid() || !closedAtDayjs.isValid()) {
    return undefined
  }

  return `${openedAtDayjs.format('HH:mm')}~${closedAtDayjs.format('HH:mm')}`
}

const getNowIsAble = (openedAt: string | undefined | null, closedAt: string | undefined | null) => {
  if (!openedAt || !closedAt) {
    return false
  }
  const A_DAY = 60 * 24
  const currentHour = dayjs().hour()
  const currentMinute = dayjs().minute()

  const [openHour, openMinute] = openedAt.split(':').map(Number)
  const [closeHour, closeMinute] = closedAt.split(':').map(Number)

  const open = openHour * 60 + openMinute
  const close = closeHour * 60 + closeMinute
  const now = currentHour * 60 + currentMinute

  // 익일
  if (openedAt > closedAt) {
    const nextDayNow = currentHour < openHour ? now + A_DAY : now
    return open <= nextDayNow && nextDayNow <= close + A_DAY
  }
  return open <= now && now <= close
}

const getNowIsAbleWithEmpty = (openedAt: string | undefined | null, closedAt: string | undefined | null) => {
  if (!openedAt || !closedAt) {
    return true
  }
  const A_DAY = 60 * 24
  const currentHour = dayjs().hour()
  const currentMinute = dayjs().minute()

  const [openHour, openMinute] = openedAt.split(':').map(Number)
  const [closeHour, closeMinute] = closedAt.split(':').map(Number)

  const open = openHour * 60 + openMinute
  const close = closeHour * 60 + closeMinute
  const now = currentHour * 60 + currentMinute
  // 익일
  if (openedAt > closedAt) {
    const nextDayNow = currentHour < openHour ? now + A_DAY : now
    return open <= nextDayNow && nextDayNow <= close + A_DAY
  }

  return open <= now && now <= close
}

const formatDateKo = (date: string, format: string) => {
  return dayjs(date).locale('ko').format(format)
}

const formatDate = (date: string, format: string) => {
  const locale = process.env.NEXT_PUBLIC_LANGUAGE || 'ko-KR'
  switch (locale) {
    case 'en':
      return dayjs(date).locale('en').format(format)
    default:
      return formatDateKo(date, format)
  }
}

const formatHourMinKo = (timeString: string): string => {
  const timeParts = timeString.split(':')
  let hours = parseInt(timeParts[0])
  const minutes = parseInt(timeParts[1])
  let period = '오전'

  if (hours >= 12) {
    period = '오후'
    if (hours > 12) {
      hours -= 12
    }
  }

  return `${period} ${hours.toString().padStart(2, '0')}시 ${minutes.toString().padStart(2, '0')}분`
}

// HH:mm:ss 형식
const formatHourMin = (timeString: string): string => {
  const locale = process.env.NEXT_PUBLIC_LANGUAGE || 'ko-KR'
  switch (locale) {
    case 'en': {
      const timeParts = timeString.split(':')
      let hours = parseInt(timeParts[0])
      const minutes = parseInt(timeParts[1])
      let period = 'AM'

      if (hours >= 12) {
        period = 'PM'
        if (hours > 12) {
          hours -= 12
        }
      }
      return `${period} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
    }
    default:
      return formatHourMinKo(timeString)
  }
}

// 2017-03-16T17:40:00+09:00 형식 (ISO 8601), todo 사용안하면 제거해야함
function isoToHourMinKo(inputTime: string, isPeriod = true): string {
  const dateObject = dayjs(inputTime)

  const hours = dateObject.hour()
  const minutes = dateObject.minute()

  const isAfternoon = hours >= 12
  const formattedHours = isAfternoon ? hours - 12 : hours
  const timeStr = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}`
  if (!isPeriod) {
    return timeStr
  }
  return `${isAfternoon ? '오후' : '오전'} ${timeStr}`
}

// date 객체 형식, , todo 사용안하면 제거해야함
const dateToHourMin = (date: Date, isPeriod?: boolean) => {
  const hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')

  if (hours > 12) {
    return `${isPeriod ? '오후' : ''} ${hours - 12}:${minutes}`
  } else if (hours == 12) {
    return `${isPeriod ? '오후' : ''} ${12}:${minutes}`
  } else {
    return `${isPeriod ? '오후' : ''} ${hours}:${minutes}`
  }
}

type FormatToHourMinProps = {
  time: string | Date
  useLocale?: boolean
}

const getHourMinNumber = (time: string | Date): { hours: number; minutes: number } => {
  if (typeof time === 'string') {
    const dateObject = dayjs(time)
    return {
      hours: dateObject.hour(),
      minutes: dateObject.minute()
    }
  }
  return {
    hours: time.getHours(),
    minutes: time.getMinutes()
  }
}
const getHourMinString = (hours: number, minutes: number) => {
  return `${String(hours).padStart(2, '0')}시 ${String(minutes).padStart(2, '0')}분`
}
const formatToHourMin = ({ time, useLocale = false }: FormatToHourMinProps) => {
  const { hours, minutes } = getHourMinNumber(time)
  if (!useLocale) {
    return getHourMinString(hours, minutes)
  }
  const calculatedHour = hours > 12 ? hours - 12 : hours
  const formattedTime = getHourMinString(calculatedHour, minutes)
  return hours >= 12 ? `오후 ${formattedTime}` : `오전 ${formattedTime}`
}

const isAfterDate = (firstTime: string | Date, secondTime: string | Date, format: string) => {
  return dayjs(firstTime, format).isAfter(dayjs(secondTime, format))
}

export const DateUtils = {
  secToMin,
  secToMinSec,
  removeSeconds,
  getBusinessHours,
  getNowIsAble,
  getNowIsAbleWithEmpty,
  formatDate,
  formatHourMin,
  dateToHourMin,
  isoToHourMinKo,
  formatToHourMin,
  isAfterDate
}
